import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { CredentialsService } from "./core/credentials.service";
import { I18nService } from "./core/i18n.service";
import { map, retry, catchError, tap } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from "./shared/shared.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public credentialsService: CredentialsService,
    public translateService: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    private sharedService: SharedService,

  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    const token = this.credentialsService.credentials
      ? this.credentialsService.credentials
      : "";
    if (!token) {
      req = req.clone({
        url: environment.base_url + req.url,
        headers: req.headers.set(
          "Accept-Language",
          this.translateService.currentLang
        ),
      });
    } else {
      req = req.clone({
        url: environment.base_url + req.url,
        headers: req.headers.set(
          "Accept-Language",
          this.translateService.currentLang
        ),
        setHeaders: {
          // "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json",
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        },
      });
    }
    
    return next.handle(req).pipe(
      // tap((event) => {
      //   if (event instanceof HttpResponse) {
      //     console.log("all looks good");
      //     // http response status code
      //     console.log("Response received for:", event.url);
      //     console.log("Status code:", event.status);
      //   }
      // }),
      catchError((error: HttpErrorResponse) => {
        
        
        const { status } = error;
        const [ message ] = error?.error?.message || ['Ha ocurrido un error'];

        switch (status) {
          case 500:
            this.sharedService.showErrorMessage('Error en el servidor, por favor intente más tarde');
            this.dialog.closeAll();
            break;
          case 401:
            this.sharedService.showErrorMessage(message);
            this.dialog.closeAll();
            this.credentialsService.setCredentials();
            this.router.navigate(["/login"]);
            break;
          default:
            return throwError(() => {
              throw error;
            });
        }
      })
    );
  }
}
